<template>
  <div class="table-wrapper">
    <h2 id="ipSummaryTitle" v-if="title"> {{ title }} </h2>
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown trigger="click"
        @command="(command)=>{ this.$emit('handleTableCommands', {command: command, ref: 'ipSummary', csvName: 'ipsummary_table', headers: [], excludeHeaders: ['Details']})}">
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div ref="ipSummary">
      <table class="hiaTable">
        <thead>
          <tr>
            <th style="width: 190px;"> </th>
            <th> Total DRGs </th>
            <th> DRGs w/o Query Recomm.
              <el-tooltip effect="dark" content="Only includes DRG recommendations where Auditor Rec. Query Affects DRG is unchecked" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </th>
            <th> Total Codes <el-tooltip effect="dark" content="Combined accuracy rate for CM and PCS codes" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip> </th>
            <th> ICD-10 CM Codes </th>
            <th> ICD-10 PCS Codes </th>
            <th> Patient Status Codes </th>
            <th> Point of Origin Codes </th>
            <th> POA Indicators </th>
          </tr>
        </thead>
        <template v-if="item.totalRecords">
          <tr style="background-color: #FAFAFA;">
            <td class="strong titleCol"> Total Reviewed </td>
            <td> {{item.totalRecords}} </td>
            <td> {{item.totalRecords}} </td>
            <td> {{item.totalCodesReviewed}} </td>
            <td> {{item.dxTotalReviewed}} </td>
            <td> {{item.pxTotalReviewed}} </td>
            <td> {{item.patStat}} </td>
            <td> {{item.poo}} </td>
            <td> {{item.poaTotalReviewed}} </td>
          </tr>

          <tr>
            <td class="strong titleCol"> Total Recommendations </td>
            <td> {{item.drgChanges}} </td>
            <td> {{item.asIsDRGChanges}} </td>
            <td> {{item.totalCodesRecommended}} </td>
            <td> {{item.dxTotalRec}} </td>
            <td> {{item.pxTotalRec}} </td>
            <td> {{item.patStatChanges}} </td>
            <td> {{item.pooChanges}} </td>
            <td> {{item.poaRecommendations}} </td>
          </tr>

          <tr>
            <td>&nbsp;</td>
          </tr>

          <tr style="background-color: #FAFAFA;">
            <td class="strong titleCol"> No Recommendations </td>
            <td> {{item.drgNoRec}} </td>
            <td> {{item.drgWOQueryNoRec}} </td>
            <td> {{item.totalCodesNoRec}} </td>
            <td> {{item.dxNoRec}} </td>
            <td> {{item.pxNoRec}} </td>
            <td> {{item.patStatNoRec}} </td>
            <td> {{item.pooNoRec}} </td>
            <td> {{item.poaNoRecommendations}} </td>
          </tr>

          <tr>
            <td class="strong titleCol"> Additions </td>
            <td></td>
            <td></td>
            <td> {{item.totalCodesAdditions}} </td>
            <td>{{item.dxAdditions}} </td>
            <td>{{item.pxAdditions}}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr style="background-color: #FAFAFA;">
            <td class="strong titleCol"> Deletions </td>
            <td> </td>
            <td> </td>
            <td> {{item.totalCodesDeletions}} </td>
            <td> {{item.dxDeletions}} </td>
            <td> {{item.pxDeletions}} </td>
            <td> </td>
            <td> </td>
            <td> </td>
          </tr>

          <tr>
            <td class="strong titleCol"> Revisions </td>
            <td> {{item.drgChanges}} </td>
            <td> {{item.asIsDRGChanges}} </td>
            <td> {{item.totalCodesRev}} </td>
            <td> {{item.dxRevisions}} </td>
            <td> {{item.pxRevisions}} </td>
            <td> {{item.patStatChanges}} </td>
            <td> {{item.pooChanges}} </td>
            <td> {{item.poaRecommendations}} </td>
          </tr>

          <tr style="background-color: #FAFAFA;">
            <td class="strong titleCol"> Accuracy Rate </td>
            <td> {{ !item.totalRecords ? 'N/A' : formatPercentage(item.totalDRGs) }} </td>
            <td> {{ !item.totalRecords ? 'N/A' : formatPercentage(item.totalDRGNoQuery) }} </td>
            <td> {{ !item.totalCodesReviewed ? 'N/A' : formatPercentage(item.totalCodes) }} </td>
            <td> {{ !item.dxTotalReviewed ? 'N/A' : formatPercentage(item.cmCodes) }} </td>
            <td> {{ !item.pxTotalReviewed ? 'N/A' : formatPercentage(item.pcsCodes) }} </td>
            <td> {{ !item.patStat ? 'N/A' : formatPercentage(item.patStatCodes) }} </td>
            <td> {{ !item.poo ? 'N/A' : formatPercentage(item.pooCodes) }} </td>
            <td> {{ !item.poaTotalReviewed ? 'N/A' : formatPercentage(item.poaCodes) }} </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="9" style="opacity: .8;">No Summary Data to Display</td>
        </tr>
      </table>

      <table style="margin-top: 30px;" class="hiaTable">
        <thead>
          <tr>
            <th> Total Financial Changes </th>
            <th> Potential Increases </th>
            <th> # Increases </th>
            <th> Potential Decreases </th>
            <th> # Decreases </th>
            <th> Net Dollar Impact </th>
          </tr>
        </thead>
        <tr v-if="item.totalRecords">
          <td>
            {{!isNaN(item.reimbIncRecords) && !isNaN(item.reimbDecRecords) ? item.reimbIncRecords + item.reimbDecRecords : null}}
          </td>
          <td :class="`${item.reimbInc < 0 ? 'negative' : ''}`"> ${{formatMoney(item.reimbInc)}} </td>
          <td> {{item.reimbIncRecords}} </td>
          <td :class="`${item.reimbDec < 0 ? 'negative' : ''}`"> ${{formatMoney(item.reimbDec)}} </td>
          <td> {{item.reimbDecRecords}} </td>
          <td :class="`${item.reimbDiff < 0 ? 'negative' : ''}`"> ${{formatMoney(item.reimbDiff)}} </td>
        </tr>
        <tr v-else>
          <td colspan="6" style="opacity: .8;">No Financial Data to Display</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import money from '@/mixins/money'
  export default {
    name: 'IPBasicSummary',
    props: ['item', 'title'],
    mixins: [money],
    data: function () {
      return {
      }
    },
    methods: {
      formatPercentage(value) {
        return `${(value * 100).toFixed(2)}%`
      }
    }
  }
</script>

<style scoped>
  table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th {
    color: #909399;
    font-weight: 600;
  }

  td,
  th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
    font-size: 12px;
    text-align: center;
  }

  thead {
    text-align: left !important;
    padding-top: 5px;
  }

  h2 {
    margin: 20px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  .titleCol {
    text-align: left !important;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>