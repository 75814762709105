<template>
  <div class="table-wrapper">
    <h2 id="OPSummaryTitle" v-if="title"> {{ title }} </h2>
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown trigger="click"
        @command="(command)=>{ this.$emit('handleTableCommands', {command: command, ref: `opSummary_${title}`, csvName: `opSummary_${title}_table`, headers: [], excludeHeaders: ['Details']})}">
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <table :ref="`opSummary_${title}`" class="hiaTable">
      <thead>
        <tr>
          <th style="width: 190px;"> </th>
          <th> Total APC </th>
          <th> APC w/o Query Rec. </th>
          <th> Total Codes
            <el-tooltip effect="dark" content="Combined accuracy rate for CM, PCS, CPT, Modifier, EM, I&I and S&I codes" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </th>
          <th> ICD-10 CM Codes </th>
          <th> ICD-10 PCS Codes </th>
          <th> CPT Codes </th>
          <th> Modifier Codes </th>
          <th> EM Codes </th>
          <th> I&I Codes </th>
          <th> S&I Codes </th>
        </tr>
      </thead>
      <template v-if="item.totalRecords">
        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol"> Total Reviewed </td>
          <td> {{item.apcTotalReviewed}} </td>
          <td> {{item.apcTotalReviedwoQuery}} </td>
          <td> {{item.totalCodesReviewed}} </td>
          <td> {{item.dxTotalReviewed}} </td>
          <td> {{item.pxTotalReviewed}} </td>
          <td> {{item.cptTotalReviewed}} </td>
          <td> {{item.modTotalReviewed}} </td>
          <td> {{item.emTotalReviewed}} </td>
          <td> {{item.iiTotalReviewed}} </td>
          <td> {{item.siTotalReviewed}} </td>
        </tr>

        <tr>
          <td class="strong titleCol"> Total Recommendations </td>
          <td> {{item.apcTotalRec}} </td>
          <td> {{item.totalAPCRecwoQuery}} </td>
          <td> {{item.totalCodesRecommended}} </td>
          <td> {{item.dxTotalRec}} </td>
          <td> {{item.pxTotalRec}} </td>
          <td> {{item.cptTotalRec}} </td>
          <td> {{item.modTotalRec}} </td>
          <td> {{item.emTotalRec}} </td>
          <td> {{item.iiTotalRec}} </td>
          <td> {{item.siTotalRec}} </td>
        </tr>

        <tr>
          <td>&nbsp;</td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol"> No Recommendations </td>
          <td> {{item.apcNoRec}} </td>
          <td> {{item.apcNoRec}} </td>
          <td> {{item.totalCodesNoRec}} </td>
          <td> {{item.dxNoRec}} </td>
          <td> {{item.pxNoRec}} </td>
          <td> {{item.cptNoRec}} </td>
          <td> {{item.modNoRec}} </td>
          <td> {{item.emNoRec}} </td>
          <td> {{item.iiNoRec}} </td>
          <td> {{item.siNoRec}} </td>
        </tr>

        <tr>
          <td class="strong titleCol"> Additions </td>
          <td> {{item.apcAdditions}} </td>
          <td> {{item.apcAdditions_WoQuery}} </td>
          <td> {{item.totalCodesAdditions}} </td>
          <td>{{item.dxAdditions}} </td>
          <td>{{item.pxAdditions}} </td>
          <td>{{item.cptAdditions}} </td>
          <td>{{item.modAdditions}} </td>
          <td>{{item.emAdditions}} </td>
          <td>{{item.iiAdditions}} </td>
          <td>{{item.siAdditions}} </td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol"> Deletions </td>
          <td> {{item.apcDeletions}} </td>
          <td> {{item.apcDeletions_Woquery}} </td>
          <td> {{item.totalCodesDeletions}} </td>
          <td> {{item.dxDeletions}} </td>
          <td> {{item.pxDeletions}} </td>
          <td> {{item.cptDeletions}} </td>
          <td> {{item.modDeletions}} </td>
          <td> {{item.emDeletions}} </td>
          <td>{{item.iiDeletions}} </td>
          <td>{{item.siDeletions}} </td>
        </tr>

        <tr>
          <td class="strong titleCol"> Revisions </td>
          <td> {{item.apcRevisions}} </td>
          <td> {{item.apcRevisions_Woquery}} </td>
          <td> {{item.totalCodesRev}} </td>
          <td> {{item.dxRevisions}} </td>
          <td> {{item.pxRevisions}} </td>
          <td> {{item.cptRevisions}} </td>
          <td> {{item.modRevisions}} </td>
          <td> {{item.emRevisions}} </td>
          <td>{{item.iiRevisions}} </td>
          <td>{{item.siRevisions}} </td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol"> Accuracy Rate </td>
          <td> {{ !item.apcTotalReviewed ? 'N/A' : formatPercentage(item.apcCodes) }} </td>
          <td> {{ !item.apcTotalReviedwoQuery ? 'N/A' : formatPercentage(item.apcCodeswoQuery) }} </td>
          <td> {{ !item.totalCodesReviewed ? 'N/A' : formatPercentage(item.totalCodes) }} </td>
          <td> {{ !item.dxTotalReviewed ? 'N/A' : formatPercentage(item.cmCodes) }} </td>
          <td> {{ !item.pxTotalReviewed ? 'N/A' : formatPercentage(item.pcsCodes) }} </td>
          <td> {{ !item.cptTotalReviewed ? 'N/A' : formatPercentage(item.cptCodes) }} </td>
          <td> {{ !item.modTotalReviewed ? 'N/A' : formatPercentage(item.modCodes) }} </td>
          <td> {{ !item.emTotalReviewed ? 'N/A' : formatPercentage(item.emCodes) }} </td>
          <td> {{ !item.iiTotalReviewed ? 'N/A' : formatPercentage(item.iiCodes) }} </td>
          <td> {{ !item.siTotalReviewed ? 'N/A' : formatPercentage(item.siCodes) }} </td>
        </tr>
      </template>
    </table>
    <!--
    <table style="margin-top: 30px;" class="hiaTable">
      <thead>
        <tr>
          <th> Total Financial Changes </th>
          <th> Potential Increases </th>
          <th> # Increases </th>
          <th> Potential Decreases </th>
          <th> # Decreases </th>
          <th> Net Dollar Impact </th>
        </tr>
      </thead>
      <tr v-if="item.totalRecords">
        <td>
          {{!isNaN(item.reimbIncRecords) && !isNaN(item.reimbDecRecords) ? item.reimbIncRecords + item.reimbDecRecords : null}}
        </td>
        <td> ${{formatMoney(item.reimbInc)}} </td>
        <td> {{item.reimbIncRecords}} </td>
        <td> ${{formatMoney(item.reimbDec)}} </td>
        <td> {{item.reimbDecRecords}} </td>
        <td> ${{formatMoney(item.reimbDiff)}} </td>
      </tr>
      <tr v-else>
        <td colspan="6" style="opacity: .8;">No Financial Data to Display</td>
      </tr>
    </table> -->
  </div>
</template>

<script>
  import money from '@/mixins/money'
  export default {
    name: 'OPBasicSummary',
    props: ['item', 'title'],
    mixins: [money],
    data: function () {
      return {
      }
    },
    methods: {
      formatPercentage(value) {
        return `${(value * 100).toFixed(2)}%`
      }
    }
  }
</script>

<style scoped>
  table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th {
    color: #909399;
    font-weight: 600;
  }

  td,
  th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
    font-size: 12px;
    text-align: center;
  }

  thead {
    text-align: left !important;
    padding-top: 5px;
  }

  h2 {
    margin: 20px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  .titleCol {
    text-align: left !important;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>