<template>
  <div class="table-wrapper">
    <h2 id="OPSummaryTitle" v-if="title">{{ title }}</h2>
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown
        trigger="click"
        @command="
          command => {
            this.$emit('handleTableCommands', { command: command, ref: `ascSummary_${title}`, csvName: `ascSummary_${title}_table`, headers: [], excludeHeaders: ['Details'] })
          }
        "
      >
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <table :ref="`ascSummary_${title}`" class="hiaTable">
      <thead>
        <tr>
          <th style="width: 190px;"></th>
          <th>
            Total Codes
            <el-tooltip effect="dark" content="Combined accuracy rate for CM, CPT with Reimb., CPT without Reimb. and Modifier codes" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </th>
          <th>ICD-10 CM Codes</th>
          <th>CPT with Reimb.</th>
          <th>CPT without Reimb.</th>
          <th>Modifier Codes</th>
        </tr>
      </thead>
      <template v-if="item.totalRecords">
        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol">Total Reviewed</td>
          <td>{{ item.totalCodesReviewedAsc }}</td>
          <td>{{ item.dxTotalReviewed }}</td>
          <td>{{ item.asc_reimbTotal }}</td>
          <td>{{ item.asc_noReimbTotal }}</td>
          <td>{{ item.modTotalReviewed }}</td>
        </tr>

        <tr>
          <td class="strong titleCol">Total Recommendations</td>
          <td>{{ item.totalCodesRecommendedAsc }}</td>
          <td>{{ item.dxTotalRec }}</td>
          <td>{{ item.asc_reimbTotalRec }}</td>
          <td>{{ item.asc_noReimbTotalRec }}</td>
          <td>{{ item.modTotalRec }}</td>
        </tr>

        <tr>
          <td>&nbsp;</td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol">No Recommendations</td>
          <td>{{ item.totalCodesNoRecAsc }}</td>
          <td>{{ item.dxNoRec }}</td>
          <td>{{ item.asc_numCptReimbNoRec }}</td>
          <td>{{ item.asc_numCptNoReimbNoRec }}</td>
          <td>{{ item.modNoRec }}</td>
        </tr>

        <tr>
          <td class="strong titleCol">Additions</td>
          <td>{{ item.totalCodesAdditionsAsc }}</td>
          <td>{{ item.dxAdditions }}</td>
          <td>{{ item.asc_numCptReimbAdditions }}</td>
          <td>{{ item.asc_numCptNoReimbAdditions }}</td>
          <td>{{ item.modAdditions }}</td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol">Deletions</td>
          <td>{{ item.totalCodesDeletionsAsc }}</td>
          <td>{{ item.dxDeletions }}</td>
          <td>{{ item.asc_numCptReimbDeletions }}</td>
          <td>{{ item.asc_numCptNoReimbDeletions }}</td>
          <td>{{ item.modDeletions }}</td>
        </tr>

        <tr>
          <td class="strong titleCol">Revisions</td>
          <td>{{ item.totalCodesRevAsc }}</td>
          <td>{{ item.dxRevisions }}</td>
          <td>{{ item.asc_numCptReimbRevisions }}</td>
          <td>{{ item.asc_numCptNoReimbRevisions }}</td>
          <td>{{ item.modRevisions }}</td>
        </tr>

        <tr style="background-color: #FAFAFA;">
          <td class="strong titleCol">Accuracy Rate</td>
          <td>{{ !item.totalCodesReviewedAsc ? 'N/A' : formatPercentage(item.totalCodes) }}</td>
          <td>{{ !item.dxTotalReviewed ? 'N/A' : formatPercentage(item.cmCodes) }}</td>
          <td>{{ !item.asc_reimbTotal ? 'N/A' : formatPercentage(item.cptWithReimb) }}</td>
          <td>{{ !item.asc_noReimbTotal ? 'N/A' : formatPercentage(item.cptWithoutReimb) }}</td>
          <td>{{ !item.modTotalReviewed ? 'N/A' : formatPercentage(item.modCodes) }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
  import money from '@/mixins/money'
  export default {
    name: 'OPBasicSummary',
    props: ['item', 'title'],
    mixins: [money],
    data: function() {
      return {}
    },
    methods: {
      formatPercentage(value) {
        return `${(value * 100).toFixed(2)}%`
      }
    }
  }
</script>

<style scoped>
  table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th {
    color: #909399;
    font-weight: 600;
  }

  td,
  th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
    font-size: 12px;
    text-align: center;
  }

  thead {
    text-align: left !important;
    padding-top: 5px;
  }

  h2 {
    margin: 20px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  .titleCol {
    text-align: left !important;
  }

  .hiaTable tr td {
    text-align: right;
  }
</style>
